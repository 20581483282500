var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VsCard',[_c('div',{staticClass:"vs-flex vs-gap-3 va-items-center vs-flex-wrap"},[_c('div',{staticClass:"vs-h500 vs-flex-auto"},[_vm._v(" "+_vm._s(_vm.$t('lists.contact.interest.tagDistribution'))+" ")]),_c('div',[_c('VsSelect',{staticStyle:{"width":"128px"},attrs:{"size":"small","labelHidden":"","options":[
                        {
                            label: _vm.$t('lists.contact.interest.forClick'),
                            value: 'click',
                        },
                        {
                            label: _vm.$t('lists.contact.interest.forOpen'),
                            value: 'open',
                        } ]},model:{value:(_vm.graphType),callback:function ($$v) {_vm.graphType=$$v},expression:"graphType"}})],1)]),(_vm.tags.length > 0)?_c('VsChart',{attrs:{"options":_vm.chartOptions}}):_c('div',{staticClass:"vs-h200 vs-text-grey-600 vs-text-center vs-flex vs-items-center vs-justify-center",staticStyle:{"height":"350px"}},[_vm._v(" "+_vm._s(_vm.$t('lists.contact.interest.noStatistics'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }