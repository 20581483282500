

































import { Component, Vue } from 'vue-property-decorator'
import { getContactInterests } from '@/api/consoleApi/recipients'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsChart from '@/components/VsChart/Index.vue'

@Component({
    name: 'ContactInterests',
    components: {
        VsSectionHeader,
        VsChart,
    },
})
export default class extends Vue {
    private loading = false
    private graphType = 'click'
    private stats: any = null

    get listId () {
        return this.$route?.params?.listId
    }

    get contactId () {
        return this.$route?.params?.contactId
    }

    get tags () {
        if (!this.stats || !this.stats[this.graphType]) return []
        return this.stats[this.graphType].map((el: any) => el.label)
    }

    get values () {
        if (!this.stats || !this.stats[this.graphType]) return []
        return this.stats[this.graphType].map((el: any) => el.value)
    }

    get chartOptions () {
        return {
            xAxis: {
                type: 'category',
                axisLabel: {
                    rotate: 25,
                    margin: 15,
                },
                data: this.tags,
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data: this.values,
                    type: 'bar',
                    itemStyle: {
                        color: '#DA5D6E',
                    },
                },
            ],
        }
    }

    beforeMount () {
        this.getContactInterests()
    }

    private async getContactInterests () {
        try {
            const resp = await getContactInterests(this.listId, this.contactId)
            this.stats = resp.data
        } catch (e) {
            this.stats = null
        }
    }
}
